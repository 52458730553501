<template>
  <v-content>
    <v-container fluid fill-height grid-list-lg>
      <v-layout class="mb-4 pb-4" wrap align-center justify-center>
        <v-flex xs12 sm10 md8 lg6 xl4>
          <v-card v-if="suply">
            <v-toolbar class="elevation-0" color="accent" dark dense>
              <v-toolbar-title class="subheading font-weight-regular">
                {{ suply.type }} - {{ suply.name }} ({{ code }})
              </v-toolbar-title>
            </v-toolbar>
            <div v-if="suply.photo != ''" style="position:relative;">
              <v-img :src="suply.photo"></v-img>
              <v-btn bottom right fab icon absolute color="primary" @click="clickActions(3)">
                <template v-if="!btnUpload">
                  <v-icon>add</v-icon>
                </template>
                <template v-else>
                  <v-icon>visibility_off</v-icon>
                </template>
              </v-btn>
            </div>

            <v-card-text>
              <div class="text-xs-center">
                <h2 class="display-3">{{ suply.stock }}</h2>
                <h3 class="subheading">Stock Actual</h3>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text v-if="btnUpload">
              <template v-if="!photoUpload">
                <v-flex xs12 class="text-xs-center">
                  <croppa
                    v-model="photoCut"
                    style="margin:0 auto;"
                    :width="200"
                    :height="200"
                    :quality="9"
                    disable-click-to-choose
                    :prevent-white-space="true"
                    :zoom-speed="8"
                    initial-position="center"
                    placeholder=""
                    placeholder-color="#000"
                    :placeholder-font-size="24"
                    canvas-color="#eee"
                    :show-remove-button="true"
                    remove-button-color="black"
                    :remove-button-size="30"
                    :show-loading="true"
                    :loading-size="20"
                    @file-choose="uploadFoto = true"
                  >
                  </croppa>
                </v-flex>
                <v-flex xs12 class="text-xs-center">
                  <v-btn color="accent" @click="chooseFile">
                    Elegir archivo...
                  </v-btn>
                  <v-btn v-if="uploadFoto" color="success" @click="uploadPhoto">
                    Subir foto
                  </v-btn>
                </v-flex>
              </template>
            </v-card-text>
            <v-card-text v-if="btnFutureStock">
              <div class="px-2">
                <h3 class="title">
                  Pedidos donde se utiliza hasta el
                  {{
                    $moment()
                      .add(30, 'days')
                      .format('DD-MM-YYYY')
                  }}
                </h3>
                <v-data-table
                  :headers="[
                    { text: 'Pedido', align: 'left', sortable: false },
                    { text: 'Cantidad', align: 'right', sortable: false }
                  ]"
                  :items="productsFutureStock"
                  hide-actions
                  class="elevation-0"
                >
                  <template slot="items" slot-scope="props">
                    <td class="text-xs-left">
                      {{ props.item.id }}
                    </td>
                    <td class="text-xs-right">
                      {{ sumInsumos(props.item.receta) }}
                    </td>
                  </template>
                  <template slot="footer">
                    <td class="text-xs-left">
                      <strong>Total</strong>
                    </td>
                    <td class="text-xs-right" colspan="1">
                      <strong>
                        {{ totalInsumos }}
                      </strong>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-card-text>
            <v-card-text v-if="btnStock">
              <div class="px-2">
                <form novalidate @submit.stop.prevent>
                  <v-layout wrap align-center justify-center>
                    <v-flex>
                      <v-text-field
                        v-model="stock"
                        hide-details
                        label="Ingrese Stock"
                        solo
                        @keyup.enter="submitCode"
                      ></v-text-field>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn color="primary" class="mx-0" large @click="updateStock">
                        Actualizar
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </form>
              </div>
            </v-card-text>
            <v-card-text v-if="btnPrice">
              <div class="px-2">
                <form novalidate @submit.stop.prevent>
                  <v-layout wrap align-center justify-center>
                    <v-flex xs12>
                      <h2 class="display-1 text-xs-center">Precio: {{ suply.price | toPrice }}</h2>
                    </v-flex>
                    <v-flex>
                      <v-radio-group v-model="priceType" hide-details>
                        <v-radio :label="`CON IVA`" :value="1"></v-radio>
                        <v-radio :label="`SIN IVA`" :value="2"></v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        v-model="priceSuply"
                        hide-details
                        :label="priceType === 1 ? 'Precio con iva' : 'Precio sin iva'"
                        solo
                        type="number"
                        @keyup.enter="submitCode"
                      ></v-text-field>
                    </v-flex>
                    <v-flex shrink>
                      <v-btn color="primary" class="my-0 mx-0" large @click="updatePrice">
                        Actualizar
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </form>
              </div>
            </v-card-text>
            <v-card-actions class="pt-4 px-4">
              <v-btn color="primary" block @click="clickActions(1)">
                <template v-if="!btnStock">
                  Stock
                </template>
                <template v-else>
                  Ocultar
                </template>
              </v-btn>
              <v-btn color="warning" block @click="clickActions(2)">
                <template v-if="!btnFutureStock">
                  Stock Futuro
                </template>
                <template v-else>
                  Ocultar
                </template>
              </v-btn>
              <v-btn color="error" block @click="clickActions(4)">
                <template v-if="!btnUpload">
                  Cambiar Precio
                </template>
                <template v-else>
                  Ocultar
                </template>
              </v-btn>
            </v-card-actions>
            <v-card-actions class="px-4 pb-4">
              <v-btn color="accent" block @click="openCam">
                Escanear otro producto
              </v-btn>
            </v-card-actions>
          </v-card>
          <template v-if="empty">
            <v-card>
              <v-card-text>
                <div class="text-xs-center title">
                  No existe el codigo ingresado
                </div>
              </v-card-text>
              <v-card-actions class="px-4 pb-4">
                <v-btn color="accent" block @click="$router.back()">
                  Volver
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
/* eslint-disable vue/require-prop-types */
import { GET_INSUMOS } from '@/config'

export default {
  name: 'QrSupply',
  props: ['code'],
  data() {
    return {
      title: 'Inventarios realizados',
      stock: null,
      suply: null,
      photoCut: null,
      photoUpload: null,
      productsFutureStock: [],
      priceType: 1,
      priceSuply: null,
      empty: false,
      btnUpload: false,
      btnStock: false,
      btnFutureStock: false,
      uploadFoto: false,
      btnPrice: false
    }
  },
  computed: {
    totalInsumos() {
      let total = 0
      this._.forEach(this.productsFutureStock, value => {
        total += this._.sumBy(value.receta, 'quantity')
      })
      return total
    }
  },
  watch: {},
  mounted() {
    const { code } = this
    if (code) {
      this.getInfo(code)
    }
  },
  methods: {
    async getInfo(code) {
      this.loading = true
      try {
        const res = await this.$http.get(`${GET_INSUMOS}?code=${code}`)
        if (res.data[0]) {
          this.suply = res.data[0]
          return
        }
        this.empty = true
      } catch (error) {
        this.empty = true
      }
    },
    async updateStock() {
      const { stock, code } = this
      try {
        await this.$http.put(`${GET_INSUMOS}/qr/update-stock`, {
          code,
          stock
        })
        if (code) {
          this.getInfo(code)
          this.reset()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updatePrice() {
      const { priceType, priceSuply, code } = this
      try {
        await this.$http.put(`${GET_INSUMOS}/qr/update-price`, {
          code,
          type: priceType,
          price: priceSuply
        })
        if (code) {
          this.getInfo(code)
          this.reset()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async uploadPhoto() {
      this.photoUpload = this.photoCut.generateDataUrl()
      const { code, photoUpload } = this
      try {
        await this.$http.put(`${GET_INSUMOS}/qr/update-photo`, {
          code,
          photo: photoUpload
        })
        if (code) {
          this.getInfo(code)
          this.reset()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getStock() {
      const { code } = this
      try {
        const res = await this.$http.get(`${GET_INSUMOS}/qr/future-stock`, {
          params: { code }
        })
        this.productsFutureStock = res.data
      } catch (error) {
        console.log(error)
      }
    },
    reset() {
      this.stock = null
      this.suply = null
      this.photoCut = null
      this.photoUpload = null
      this.priceSuply = null
      this.empty = false
      this.btnUpload = false
      this.btnStock = false
      this.btnFutureStock = false
      this.btnPrice = false
    },
    clickActions(e) {
      if (e === 1) {
        this.btnStock = !this.btnStock
        this.btnFutureStock = false
        this.btnUpload = false
        this.btnPrice = false
      }
      if (e === 2) {
        this.btnFutureStock = !this.btnFutureStock
        this.btnStock = false
        this.btnUpload = false
        this.btnPrice = false
        if (this.btnFutureStock) {
          this.getStock()
        }
      }
      if (e === 3) {
        this.btnUpload = !this.btnUpload
        this.btnStock = false
        this.btnFutureStock = false
        this.btnPrice = false
      }
      if (e === 4) {
        this.btnPrice = !this.btnPrice
        this.btnStock = false
        this.btnFutureStock = false
        this.btnUpload = false
      }
    },
    chooseFile() {
      this.photoCut.chooseFile()
    },
    openCam() {
      this.$router.push({ name: 'QRStock', query: { cam: 1 } })
    },
    sumInsumos(recetas) {
      return this._.sumBy(recetas, 'quantity')
    }
  }
}
</script>
